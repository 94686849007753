export default {
    state: {
        cadastr_number: null
    },

    mutations: {
        set_cadastr (state, val) {
            state.cadastr_number = val; 
        }
    },

    getters: {
        get_current_cadastr: state => state.cadastr_number
    }
}