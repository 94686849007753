import GoogleMap from '@/core/maps/GoogleMap.js'
import YandexMap from '@/core/maps/YandexMap.js'
import OpenStreetMap from '@/core/maps/OpenStreetMap.js'
import TwoGISMap from '@/core/maps/TwoGISMap.js'
import BingMap from '@/core/maps/BingMap.js'

const lat = 55.75631886877506;
const lng = 37.62080594668173;
const zoom = 10;

export default {
    methods: {
        initMaps(map_container_name, cords = null) {
            let _lat = cords == null ? lat : cords.coords.latitude;
            let _lng = cords == null ? lng : cords.coords.longitude;

            return {
                googleMap: new GoogleMap(map_container_name, {
                    center: { lat: _lat, lng: _lng },
                    zoom: zoom
                }),
                yandexMap: new YandexMap(map_container_name, {
                    center: [_lat, _lng],
                    zoom: zoom
                }),
                openStreetMap: new OpenStreetMap(map_container_name, {
                    center: [_lat, _lng],
                    zoom: zoom
                }),
                twoGISMap: new TwoGISMap(map_container_name, {
                    center: [_lat, _lng],
                    zoom: zoom
                }),
                bingMap: new BingMap(map_container_name, {
                    center: [_lat, _lng],
                    zoom: zoom
                })
            }
        }
    }
}