export default class {
    constructor(refer, options = {}) {
        this.refer = refer;
        this.options = options;
        this.options.controls = [];
    }

    create() {
        /*global ymaps*/
        ymaps.ready(() => {
            this.map = new ymaps.Map(this.refer, this.options, {
                searchControlProvider: 'yandex#search'
            });
            this.map.controls.add('zoomControl');

            this.map.layers.add(new ymaps.Layer('https://gext.ru/rosreestr_xyz/%z/%x/%y.png', {
                projection: ymaps.projection.sphericalMercator
            }));

            this.map.events.add('click', async (e) => {
                const cords = e.get('coords');
                this.mapClickedEvent('yandexMap', {'lng': cords[0], 'lat': cords[1]});
            });

            return this.map;
        });
    }

    drawCircle(lat, lng, color) {
        ymaps.ready(() => {
            const circle = new ymaps.Circle([[lng, lat], 25], {}, {
                fillColor: color,
                zIndex: 9999
            });
            this.map.geoObjects.add(circle);
            return circle;
        });
    }

    removeCircle(ref) {
        ymaps.ready(() => {
            this.ref = ref;
            this.map.geoObjects.removeAll();
        });
    }

    setZoom(zoomLevel) {
        ymaps.ready(() => {
            this.map.setZoom(zoomLevel, {});
        });
    }

    setView(coords) {
        ymaps.ready(() => {
            this.map.setCenter([coords.lat, coords.lng]);
        });
    }

    remove() {
        ymaps.ready(() => {
            this.map.destroy(); 
        });
    }

    changeType(type) {
        ymaps.ready(() => {
            this.map.setType('yandex#' + type);
        });
    }
}