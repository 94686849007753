/*global L*/
// eslint-disable-next-line no-unused-vars
import bingTileLayer from 'leaflet-bing-layer';
import Map from './Map';

export default class extends Map {
    constructor(refer, options = {}) {
        super();
        this.refer = refer;
        this.options = options;
    }

    create() {
        if (window.superMap == undefined)
            window.superMap = L.map(this.refer).setView(this.options.center, this.options.zoom);   
        
        this.map = window.superMap;
        this.currentLayer = L.tileLayer.bing('ArKAQ_sDTaoBKWdeIfYZCARjyZ-gW5-Da91DynrA_XqfijWqeLI8rhi8BnTWXBkG').addTo(this.map)
        this.pkk = super.renderPKK(this.map);

        this.map.on('click', async (e) => {
            this.mapClickedEvent('bingMap', {'lat': e.latlng.lng, 'lng': e.latlng.lat});
        });

        this.map.on('zoomstart', () => {
            this.zoomEvent(this.map.getZoom());
        });

        this.map.on('moveend', () => {
            this.moveEvent(this.map.getCenter());
        });

        return this.map;
    }
    
    drawCircle(lat, lng, color) {
        return L.circle([lng, lat], 20, {color: color}).addTo(this.map);    
    }

    removeCircle(ref) {
        this.map.removeLayer(ref);
    }

    setZoom(zoomLevel) {
        this.map.setZoom(zoomLevel);
    }

    setView(coords) {
        this.map.setView(new L.LatLng(coords.lat, coords.lng));
    }

    remove() {
        this.map.removeLayer(this.pkk);
        this.map.removeLayer(this.currentLayer);
    }

    // eslint-disable-next-line no-unused-vars
    changeType(type) {
        return false;
    }
}