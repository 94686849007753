<template>
    <section>
        <div v-if="pending">
            <p><strong>Кадастровый номер:</strong> <a href="/">{{ get_current_cadastr }}</a></p>
            <input class="user-field" v-model="email" type="text" placeholder="Ваш email">
            <div class="actions">
                <button class="btn-white" v-on:click="$emit('btn', 'Documents')">« Назад</button>
                <button class="btn-green" v-on:click="payment()">Оплатить</button>
            </div>
        </div>
        <div v-else>
            <p>Заявка успешно отправлена!</p>
        </div>
    </section>
</template>

<style scoped>
    .user-field {
        padding: 10px;
        border: none;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin: 10px 0px;
    }   
</style>

<script>
    import InformationWindowMixin from '../../mixins/informationWindow.js'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                email: '',
                pending: true
            }  
        },
        mixins: [InformationWindowMixin],
        computed: {
            ...mapGetters(['get_current_cadastr'])
        },
        methods: {
            async payment() {
                if (this.email.length == 0)
                    return alert('Введите свой email');

                const order = await this.api.createOrder(this.checked, this.email, this.cadastr_number());

                this.pending = false;
                if (order != false)
                    window.location.href = order.url;
            }
        }   
    }
</script>