/*global L*/
import Map from './Map';

export default class extends Map {
    constructor(refer, options = {}) {
        super();
        this.refer = refer;
        this.options = options;
        this.marker = null;

        this.layer = {
            'satellite': 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
            'default': 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
        }
    }

    create() {
        if (window.superMap == undefined)
            window.superMap = L.map(this.refer).setView(this.options.center, this.options.zoom);   
        
        this.map = window.superMap;
        this.renderLayer('default');
        this.pkk = super.renderPKK(this.map);

        this.map.on('click', async (e) => {
            this.mapClickedEvent('googleMap', {'lat': e.latlng.lng, 'lng': e.latlng.lat});
        });
        
        this.map.on('zoomstart', () => {
            this.zoomEvent(this.map.getZoom());
        });

        this.map.on('moveend', () => {
            this.moveEvent(this.map.getCenter());
        });

        return this.map;
    }

    drawCircle(lat, lng, color) {
        return L.circle([lng, lat], 20, {color: color}).addTo(this.map);    
    }

    removeCircle(ref) {
        this.map.removeLayer(ref);
    }

    setView(coords) {
        this.map.setView(new L.LatLng(coords.lat, coords.lng));
    }

    setZoom(zoomLevel) {
        this.map.setZoom(zoomLevel);
    }

    remove() {
        this.map.removeLayer(this.pkk);
        this.map.removeLayer(this.currentLayer);
    }

    changeType(type) {
        this.remove();
        this.renderLayer(type);
        this.pkk = super.renderPKK(this.map);
    }

    renderLayer(type) {
        this.currentLayer = L.tileLayer(this.layer[type], {
            maxZoom: this.options.maxZoom ?? 20,
            subdomains:['mt0','mt1','mt2','mt3']
        }).addTo(this.map);
    }
}