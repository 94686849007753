<template>
    <section>
        <div v-if="!load">
            <p v-for="(answer, index) in services.answer" v-bind:key="index">
                <strong>{{ answer.name }}:</strong>
                {{ answer.description }}
            </p>
            <p v-if="services.hasOwnProperty('error')">{{ services.error }}</p>
            <hr>
            <button v-on:click="$emit('btn', 'Documents')" class="btn-green">Посмотреть доступные документы</button>
        </div>
        <div v-else>
            <p>{{ errorMessage.length == 0 ? 'Идет загрузка...' : errorMessage }}</p>
        </div>
    </section>
</template>

<script>
    import InformationWindowMixin from '../../mixins/informationWindow.js'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                showMore: false,
                answers: [],
                load: true,
                errorMessage: ''
            }
        },
        mixins: [InformationWindowMixin],
        async created() {
            this.$parent.$on('reset', () => {
                this.load = true;
                this.services = [];
                this.errorMessage = '';
                this.getInfo(this.get_current_cadastr);
            });

            this.$parent.$on('not_found', () => {
                this.load = true;
                this.errorMessage = 'Объект не найден';
            });

            this.getInfo(this.get_current_cadastr);
        }, 
        methods: {
            async getInfo(cadast) {
                this.services = await this.$clients.master.getInstance(cadast);
                localStorage.setItem('service', JSON.stringify(this.services));
                this.load = false;    
            }
        },
        computed: {
            ...mapGetters(['get_current_cadastr']),
            advanceTextButton: function () {
                return this.showMore ? 'Скрыть' : 'Подробно';
            }
        }
    }
</script>

<style scoped>
    .advanceButton {
        cursor: pointer;
        margin: 10px 0px;
        font-size: 13px;
        text-decoration: underline;
        color: #337ab7;
    }
</style>