import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './router'

import dadata_api from './core/clients/dadata_api.js'
import master_api from './core/clients/master_api.js'
import pkk_api from './core/clients/pkk_api.js'

// Vuex stores
import ObjectStore from './store/ObjectStore.js'
import MapStore from './store/MapStore.js'

Vue.config.productionTip = false

// Create clients instances
Vue.prototype.$clients = {
  dadata: new dadata_api('https://suggestions.dadata.ru/suggestions/api/4_1', 'e8e5282e003f9876d9a66e625d4b7cec5bbf9274'),
  master: new master_api('http://212.193.59.44:8080/api'),
  pkk_api: new pkk_api('https://pkk.rosreestr.ru')
};

// Make Vue instance
Vue.use(VueRouter)
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    ObjectStore,
    MapStore
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
