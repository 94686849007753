import axios from 'axios';

export default class {
    constructor(baseURL) {
        this.instance = {
            'baseURL': baseURL
        }
    }

    async getFeature(coordinates, type = 1, params = {}) {
        const endpoint = '/api/features';
        const tolerance = params.tolerance ?? 1;
        const limit = params.limit ?? 10;

        const requestUri = `${endpoint}/${type}?sq=${encodeURIComponent(`{"type": "Point", "coordinates": [${coordinates}]}`)}&tolerance=${tolerance}&limit=${limit}`;
        return await axios.get(this.instance.baseURL + requestUri);
    }

    async getFeatureText(text, type = 1, params = {}) {
        const endpoint = '/api/features';
        const tolerance = params.tolerance ?? 1;
        const limit = params.limit ?? 10;

        const requestUri = `${endpoint}/${type}?text=${encodeURIComponent(text)}&tolerance=${tolerance}&limit=${limit}`;
        return await axios.get(this.instance.baseURL + requestUri);
    }

    async getFeatureImage(extent, cadastrNumber) {
        const endpoint = '/arcgis/rest/services/PKK6';
        const requestUri = `${endpoint}/CadastreSelected/MapServer/export?dpi=96&transparent=true&format=png32&bbox=${extent.xmax},${extent.ymax},${extent.xmin},${extent.ymin}&size=723%2C550&bboxSR=102100&imageSR=102100&layers=show%3A3%2C4%2C5&layerDefs=${encodeURIComponent(`{"3":"id = '${cadastrNumber}'","4":"id = '${cadastrNumber}'","5":"id = '${cadastrNumber}'"}`)}&f=image`;
        return await axios.get(this.instance.baseURL + requestUri, {
            responseType: 'blob'
        });
    }
}