<template>
    <div class="information_window">
        <component :is="section" v-on:btn="switchSection" />
    </div>
</template>

<script>
    import Advanced from './Information/Advanced';
    import Documents from './Information/Documents';
    import Payment from './Information/Payment';

    export default {
        name: 'InformationWindow',
        data() {
            return {
                section: 'Advanced'
            }
        },
        methods: {
            switchSection(sectionName) {
                this.section = sectionName;
            }
        },
        created() {
            this.$parent.$on('update_number', () => {
                this.$emit('reset');
                this.section = 'Advanced';
            });

            this.$parent.$on('not_found', () => {
                this.$emit('not_found');
            });
        },
        components: {
            Advanced,
            Documents,
            Payment
        }
    }
</script>

<style>
    a {
        color: #337ab7;
    }
    .information_window {
        background: white;
        padding: 10px;
        position: fixed;
        top: 90px;
        left: 60px;
        z-index: 9999;
        width: 350px;
        text-align: left;
    }
    .actions {
        display: flex;
    }
    button {
        display: flex;
        margin: 5px auto;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
        padding: 10px;
        cursor: pointer;
    }
    .btn-green {
        background-color: #5cb85c;
        border-color: #4cae4c;
        color: #fff;
    }
    .btn-white {
        background-color: #fff;
        border-color: #ccc;
        color: #333;
    }
</style>