export default {
    methods: {
        cadastr_number() {
            return localStorage.getItem('cadastr_number');
        }
    },
    computed: {
        services_from_storage: function () {
            return JSON.parse(localStorage.getItem('service'));
        },

        checked: function () {
            return JSON.parse(localStorage.getItem('checked'));
        },
    }
}