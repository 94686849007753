import axios from 'axios';

export default class{
    constructor(baseURL, token) {
        this.client = axios.create({
            baseURL: baseURL,
            headers: {
                Authorization: `Token ${token}`
            }
        });
    }

    async searchByText(text, count = 5) {
        const response = await this.client.post('/rs/suggest/address', {
            count: count,
            query: text
        });

        return response.data;
    }
}