/*global L*/

import Map from './Map';

export default class extends Map {
    constructor(refer, options = {}) {
        super();
        this.refer = refer;
        this.options = options;
    }

    create() {
        if (window.superMap == undefined)
            window.superMap = L.map(this.refer).setView(this.options.center, this.options.zoom);   
        
        this.map = window.superMap;
        this.layer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);
        this.pkk = super.renderPKK(this.map);

        this.map.on('click', async (e) => {
            this.mapClickedEvent('openStreetMap', {'lat': e.latlng.lng, 'lng': e.latlng.lat});
        });

        this.map.on('zoomstart', () => {
            this.zoomEvent(this.map.getZoom());
        });

        this.map.on('moveend', () => {
            this.moveEvent(this.map.getCenter());
        });

        return this.map;
    }

    drawCircle(lat, lng, color) {
        return L.circle([lng, lat], 20, {color: color}).addTo(this.map);   
    }

    removeCircle(ref) {
        this.map.removeLayer(ref);
    }

    setZoom(zoomLevel) {
        this.map.setZoom(zoomLevel);
    }

    setView(coords) {
        this.map.setView(new L.LatLng(coords.lat, coords.lng));
    }

    remove() {
        this.map.removeLayer(this.pkk);
        this.map.removeLayer(this.layer);
    }
}