<template>
    <div class="home">
        <div ref="map" id="map" class="map"></div>    
        <Tumbler @changeMap="toggleMap" :defaultMap="currentMap" :mapType="currentType"/>
        <Search :inputAddress="address" @clickSuggest="onSearchEvent" @clickCadastr="onSearchCadastrEvent" />
        <InformationWindow v-if="information" />
        <img :src="image" v-if="image != null">
    </div>
</template>

<script>

import Tumbler from '@/components/Tumbler'
import InformationWindow from '@/components/InformationWindow'
import Search from '@/components/Search'
import MapCoreMixin from '@/mixins/mapCores'
import { mapGetters } from 'vuex'

export default {
    name: 'Home',
    mixins: [MapCoreMixin],
    data() {
        return {
            core: {
                googleMap: null,
                yandexMap: null,
                openStreetMap: null,
                twoGISMap: null,
                bingMap: null
            },
            currentMap: 'googleMap',
            currentType: null,
            circle: {
                entity: null,
                lat: undefined,
                lng: undefined,
                color: null
            },
            image: null,
            address: null,
            information: false
        }
    },
    components: {
        Tumbler,
        InformationWindow,
        Search
    },
    methods: {
        toggleMap(sys_name, type = null) {
            if (sys_name == this.currentMap && type == this.currentType)
                return;

            const map = this.core[sys_name];
            if (this.circle.entity !== null) {
                this.createCircle({
                    lat: this.circle.lat,
                    lng: this.circle.lng
                }, this.circle.color);
            }
                
            this.core[this.currentMap].remove();
            this.map = map.create();
            if (type != null)
                map.changeType(type);

            this.currentMap = sys_name;
            this.currentType = type;

            if (this.currentMap == 'yandexMap' && this.circle.entity !== null) {
                this.core[this.currentMap].setZoom(16);
                this.core[this.currentMap].setView({lat: this.circle.lng, lng: this.circle.lat});
            }

            if (this.currentMap == 'yandexMap')
                this.core[this.currentMap].setZoom(this.get_zoom);
                if (Object.keys(this.get_latlng).length != 0)
                    this.core[this.currentMap].setView(this.get_latlng);
        },

        async onMapClickedEvent(source, event) {
            if (source != this.currentMap)
                return;

            let feature = await this.pkk.getFeature(`${event.lat},${event.lng}`, 5);
            if (feature.data.features.length == 0) {
                feature = await this.pkk.getFeature(`${event.lat},${event.lng}`, 1);
            }

            if (this.circle.entity != null)
                this.removeCircle(this.circle.entity);

            this.createCircle(event, '#11ab28');
            this.information = true;

            const response = feature.data.features[0];
            const search = this.trySearchFeature(response);
            if (search) {
                this.removeCircle(this.circle.entity);
                this.createCircle(event, '#ecfc3a');
                this.$emit('update_number');
            }
        },

        async onSearchEvent(payload) {
            this.core[this.currentMap].setView({
                lat: payload.data.geo_lat,
                lng: payload.data.geo_lon
            });
            this.core[this.currentMap].setZoom(16);
            this.createCircle({
                lat: payload.data.geo_lon,
                lng: payload.data.geo_lat
            }, '#ecfc3a');
        },  

        async onSearchCadastrEvent(number) {
            let feature = await this.pkk.getFeatureText(number, 5);
            if (feature.data.features.length == 0) {
                feature = await this.pkk.getFeatureText(number, 1);
            }

            const response = feature.data.features[0];
            if (this.trySearchFeature(response)) {
                const cadast = response.attrs.cn;
                const services = await this.$clients.master.getInstance(cadast);
                this.address = response.attrs.address ?? services.answer.filter(x => x.name == "Адрес")[0].description;
            }
        },

        trySearchFeature(feature) {
            const cadastr_number = feature.attrs.cn ?? null;
            if (cadastr_number == null)
            {
                this.$emit('not_found');
                return false;
            }

            this.information = true;
            this.$store.commit('set_cadastr', cadastr_number);   
            return true; 
        },

        removeCircle(circle) {
            this.core[this.currentMap].removeCircle(circle);
        },

        createCircle(event, color) {
            this.circle = {
                'entity': this.core[this.currentMap].drawCircle(event.lat, event.lng, color),
                'lat': event.lat,
                'lng': event.lng,
                'color': color
            }
        },

        renderMaps(cords) {
            this.core = this.initMaps('map', cords);
            for (const item in this.core) {
                this.core[item].mapClickedEvent = this.onMapClickedEvent;
                this.core[item].zoomEvent = (zoomLevel) => {
                    this.$store.commit('set_zoom', zoomLevel);
                }
                this.core[item].moveEvent = (cords) => {
                    this.$store.commit('set_latlng', {
                        lat: cords.lat,
                        lng: cords.lng
                    });
                }
            }

            this.map = this.core[this.currentMap].create();
        }
    },
    async mounted() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.renderMaps(position);
            }, () => {
                this.renderMaps(null);
            });
        }  else {
            this.renderMaps(null);
        }
    },
    computed: {
        ...mapGetters(['get_zoom', 'get_lat', 'get_lng', 'get_latlng']),
        pkk() {
            return this.$clients.pkk_api;
        }
    }
}
</script>

<style>
    * {
        margin: 0px;
        padding: 0px;
    }
</style>

<style scoped>
    .map {
        width: 100%;
        height: 100vh;
        z-index: 2
    }
</style>
