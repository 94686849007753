import axios from 'axios';

export default class{
    constructor(baseURL) {
        this.client = {
            'baseURL': baseURL
        }
    }

    async getInstance(cadastral_number) {
        const response = await axios.post(`${this.client.baseURL}/get_info_cadastral/`, {
            'cadastral_number': cadastral_number
        });

        if (response.status !== 200)
            return [];

        return response.data;
    }

    async createOrder(selected_option, email, cadastral_number) {
        const _toString = JSON.stringify(selected_option);
        const response = await axios.post(`${this.client.baseURL}/create_order/`, {
            'selected_option': _toString,
            'email': email,
            'cadastral_number': cadastral_number
        });

        if (!response.data.success)
            return false;

        return response.data;
    }
}