<template>
    <div class="search">
        <header>
            <input placeholder="Введите номер или кадастровый номер" @click="display = !display" v-model="search" @change="onEnterText()" />
            <Button @click="display = false">Поиск</Button>
        </header>
        <div class="suggests" v-if="display">
            <div class="suggest" v-for="(suggest, index) in suggests" v-bind:key="index" @click="openSuggest(suggest)">{{ suggest.unrestricted_value }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                suggests: [],
                search: '',
                display: true
            }
        },
        props: {
            inputAddress: {
                required: false,
                type: String,
                default: () => null
            }
        },
        methods: {
            openSuggest(item) {
                this.$emit('clickSuggest', item);
                this.search = item.unrestricted_value;
                this.display = false;
            },

            triggerCadastrEvent(number) {
                this.$emit('clickCadastr', number);
            },

            async onEnterText() {
                // check input text is cadastr number
                if (this.search.includes(':')) {
                    this.triggerCadastrEvent(this.search);
                    return;
                }

                const response = await this.$clients.dadata.searchByText(this.search);
                this.display = true;
                this.suggests = response.suggestions;
            }
        },
        watch: {
            async inputAddress(val) {
                if (val == null || val.length == 0)
                    return;
                
                this.search = this.inputAddress;
                await this.onEnterText();
                if (this.suggests.length != 0)
                    this.openSuggest(this.suggests[0]);
            }
        }
    }
</script>

<style scoped>
    .search {
        position: fixed;
        top: 10px;
        left: 60px;
        z-index: 9999;
        width: 450px;
    }

    header {
        width: 70%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .suggests {
        background: #fff;
        margin-top: -6px;
        border: 1px solid #eee;
        text-align: left;
        cursor: pointer;
    }

    .suggest {
        border: 1px solid #eee;
        padding: 7px 5px;
    }

    .suggest:hover {
        background: #eee;
    }

    input {
        height: 25px;
        border: #eee;
        padding: 5px 15px;
        width: 90%;
    }

    button {
        margin-left: 15px;
        background: #337ab7;
        color: #fff;
    }
</style>