<template>
    <div class="tumbler">
        <div v-for="(map, index) in maps" v-bind:key="index">
            <input type="radio" name="map" :checked="map.system_name === defaultMap && map.type == mapType" @change="changeMapEvent(map.system_name, map.type)"> {{ map.name }}
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            defaultMap: {
                required: true,
                default: () => 'googleMap',
                type: String
            },
            mapType: {
                required: true,
                default: () => null
            }
        },
        data() {
            return {
                maps: [
                    {
                        name: 'Карта Яндекс',
                        system_name: 'yandexMap',
                        type: null
                    },
                    {
                        name: 'Карта Google',
                        system_name: 'googleMap',
                        type: null
                    },
                    {
                        name: 'Карта 2GIS',
                        system_name: 'twoGISMap',
                        type: null
                    },
                    {
                        name: 'OpenStreetMap',
                        system_name: 'openStreetMap',
                        type: null
                    },
                    {
                        name: 'Спутник Яндекс',
                        system_name: 'yandexMap',
                        type: 'satellite'
                    },
                    {
                        name: 'Спутник Google',
                        system_name: 'googleMap',
                        type: 'satellite'
                    },
                    {
                        name: 'Спутник Bing',
                        system_name: 'bingMap',
                        type: 'satellite'
                    },
                ]
            }
        },
        methods: {
            changeMapEvent(sys_name, type) {
                this.$emit('changeMap', sys_name, type);
            }
        }
    }
</script>

<style scoped>
    .tumbler {
        position: fixed;
        right: 60px;
        background: white;
        padding: 20px;
        top: 50px;
        z-index: 9999;
        text-align: left;
    }
</style>