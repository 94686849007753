export default {
    state: {
        zoom_level: 13,
        latlng: {}
    },

    mutations: {
        set_zoom (state, val) {
            state.zoom_level = val; 
        },
        set_latlng (state, val) {
            state.latlng = val; 
        }
    },

    getters: {
        get_zoom: state => state.zoom_level,
        get_lat: state => state.latlng.lat,
        get_lng: state => state.latlng.lng,
        get_latlng: state => state.latlng
    }
}