<template>
    <section>
        <p><strong>Кадастровый номер:</strong> <a href="/">{{ get_current_cadastr }}</a></p>
        <div class="overview">
            <div class="document" v-for="(service, index) in services.services" @click="addCheckedService(index)" v-bind:key="index">
                <div>
                    <input type="checkbox" :id="index" :value="index" v-model="checkedServices" />
                </div>
                <div>
                    <p class="document__name">{{ service.name }}</p>
                    <span class="document__description">{{ service.desc || '' }}</span>
                </div>
                <div class="document__deadline">
                    <p>{{ service.price }} руб.</p>
                    <p>{{ service.count_days }} дней</p>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="btn-white" v-on:click="$emit('btn', 'Advanced')">« Назад</button>
            <button class="btn-green" v-on:click="next()">Далее »</button>
        </div>
    </section>
</template>

<script>
    import InformationWindowMixin from '../../mixins/informationWindow.js'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                services: [],
                checkedServices: []
            }
        },
        mixins: [InformationWindowMixin],
        async created() {
            this.services = this.services_from_storage;
        },
        computed: {
            ...mapGetters(['get_current_cadastr'])
        },
        methods: {
            next() {
                localStorage.setItem('checked', JSON.stringify(this.checkedServices));
                this.$emit('btn', 'Payment');
            },
            addCheckedService(index) {
                if (this.checkedServices.includes(index))
                {
                    this.checkedServices.splice(this.checkedServices.indexOf(index), 1);
                    return;
                }

                this.checkedServices.push(index);
            }
        }
    }
</script>

<style scoped>
    .overview {
        margin-top: 10px;
    }
    .document__name {
        font-size: 14px;
        font-weight: bold;
    }
    .document__description {
        font-size: 12px;
        color: #777;
    }
    .document {
        display: flex;
        margin-bottom: 10px;
        cursor: pointer;
    }
    .document > div:nth-child(1) {
        margin-right: 10px;
    }
    .document > div:nth-child(2) {
        width: 70%;
    }
    .document__deadline {
        font-size: 14px;
    }
    .document__deadline > p:nth-child(2) {
        margin-top: 10px;
    }
</style>